.Navbar {
    height: 5rem;
    width: 100vw;
    position: fixed;
}

.navbarNav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navItem {
    margin: 0 15px;
}

.navLink {
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: #D9D9D9;
    text-decoration: none;
    opacity: 1;
    transition: opacity 0.5s;
}

.navLink:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}