.Home {
    margin: 0;
    padding: 0;    
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mainContent {
    margin: 30px 30px;
    display: flex;
    justify-content: space-between;
}

.Weather {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2D374A;
    width: 450px;
    height: 250px;
}

.Graph {
    display: flex;
    flex-direction: column;
    padding: 0
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.timeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 8px;
    transition-duration: 0.4s;
    height: 25px;
    border-radius: 10px;
    font-family: 'Open Sans';
    cursor: pointer;
}

.buttonSelected {
    background-color: #35A2EB;
}

.eventsBackground {
    background-color: #2D374A;
    width: 100%;
}

.graphBackground {
    background-color: #2D374A;
}

.weatherBackground {
    background-color: #098428;
    width: 1250px;
    height: 600px;
}

@media screen and (max-width: 1000px) {
    .top {
        flex-direction: column;
    }
}  